.productDetailSection{
    margin: 0 auto;
    padding: 0.5rem 1.5rem;
    position: relative;
}
.slideImageContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    height: 513px;   
}
.slideImageContainer img{
    width:60%;    
}
.slideImageContainer button{
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border:none;
    opacity: 0.5;
}
.slideImageContainer button:hover {
    opacity: 0.9;
}
#prevButton{   
    left:21.5%;   
}
#nextButton {   
    right: 21.5%;  
}
.chevron{ 
    height: 20px;
}
tbody th{
    text-align: start;
}
th,td{
    padding: 0.5rem 1rem;
}
#productDetailName{
    font-size: 1.5rem;
    border-bottom: 2px solid var(--color-buttonGrey);
}
.productDetailExplanation{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:2rem 0;
}

@media (max-width:960px) {
    .slideImageContainer img {
        width: 80%;
    }
    #prevButton {
        left: 13.2%;
    }
    #nextButton {
        right: 13.2%;
    }
}

@media (max-width:640px) {
    .productDetailSection {
        padding: 0.5rem 1rem;
    }
    .slideImageContainer img {
        width: 100%;
    }
    #prevButton {
        left: 24px;
    }
    #nextButton {
        right: 24px;
    }
}