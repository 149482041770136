:root{
  --color-textGrey:#313131;
  --color-lineGrey:#6f6f6f;
  --color-buttonGrey:#cacaca;
  --color-backgroundGrey:#f2f2f2;
  --color-green:#73bd44;
  --color-orange:#f68523;
  --color-blue:#24afe5;
  --color-darkBlue:#496075;
}

body{
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family: Helvetica, Arial, sans-serif;
}

h1{
  font-size: 2rem;
}

h3{
  margin:0 1rem;
}

button{
  cursor: pointer;
}

.privacy{
  margin: 1rem;
}

