footer{    
    padding: 3rem 1.5rem 0.5rem 1.5rem;
    background-color: var(--color-backgroundGrey);
}
.footerTop{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.formContainer{
    color:var(--color-textGrey);
    width:35%;
}
.formContainer button{
    border:1px solid var(--color-buttonGrey);
    background-color: var(--color-buttonGrey);
    padding: 6px;
    border-radius: 6px;
}
.formContainer h1, .sendButton{
    text-align: center;
}
.sendButton button{
    margin-top:1.5rem ;
    width:180px;
    font-size: 1.1rem;
}
.formContainer h1 {
    margin:1.5rem auto;
   
}
.formItems{
    margin:3rem 0 auto 0;
}
.formItems div:not(.doubleButton){
    margin: 2rem 0;
    font-size:1.3rem;   
}
.formItems input:not(input[type=file]), textarea, #imageForm{
    border: none;
    border-bottom: 2px solid var(--color-lineGrey);
    height:1.5rem;   
    background:none;
    outline: none;
    font-size: 1.3rem;
    padding:3px;
    width:100%;
}
#imageForm{
    border:none;
}
#unvisibleButton{
    opacity:0;   
    position: absolute;
    z-index: 1;
    width:120px;
    height: 2rem;
}
form button{
    height:2rem;
}
#chooseImage{
    width: 120px;
}
#uploadImage{  
    width: 120px;
}
#uploadImagePercentTrans{
    font-size:0.9rem;
    opacity: 0.5;
}
#uploadImagePercent {
    font-size: 1rem;
    opacity: 1;
}
.uploadContainer p{
    text-align: center;
}
#explanation {
    text-align: start;
    font-size: 0.8rem;
}
textarea{
    resize:none;
    font-family: Helvetica, Arial, sans-serif;
}
.formItems input::placeholder{
   font-size: 1.3rem;   
}
.formItems input[type=url] {
    display: none;   
}
form{
    display: flex;
    justify-content: space-evenly;
}
.map h3,h4{
    margin:1rem;
    color:var(--color-textGrey);
    font-weight: 500;
}
.map h4 a{
    text-decoration: none;
    color: var(--color-textGrey);
}
.policy{
    font-size: 0.8rem;
    text-align: justify;   
}
.formDisplayNone{
    display: none;
}
.footerBottom {
    display: flex;
    justify-content: center;
    margin-top:1rem;
}
.footerBottom p{
    font-size: 0.8rem;
    text-align: center;
    margin:auto 3rem;
}
.footerBottom p a{
    color:var(--color-textGrey)
}

@media (max-width:960px){
    .footerTop{
        flex-direction: column;
    }
    .map {
        margin-top: 3rem;
    }
    .formContainer {
        width: 450px;
    }
}

@media (max-width:600px) {  
    .formContainer {
        width: 100%;
    }
    .map{
        width: 100%;
    }
    iframe{
        width: 100%;
        height: 360px;
    }
}