.spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;    
}
header{   
    padding:0.5rem 1.5rem 0 1.5rem;
    position: sticky;
    top:0;
    background-color: white;
    z-index: 10;
    box-shadow: 0 0 60px rgb(0 0 0 / 12%);
}
.headerLogo{
    width:120px;
}
.headerTopRight{  
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.faPhone{
    height: 20px;
    margin-right: 12px;
    color: var(--color-textGrey);
}
.faEnvelope{
    height: 20px;
    color: var(--color-textGrey);
}
.headerTopRight a:not(:last-child){
    display: flex;
    align-items: center;
    color:var(--color-textGrey);
    text-decoration: none;
    font-size: 1.1rem;
    padding:3px 12px;
    margin-left:1.2rem;
    border:2px solid #cccccc;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease-in;
}
.headerTopRight a:hover{
    border-color:var(--color-textGrey);
}
.headerTopRight a:last-child {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
}
.whatsappLogo{
    width:80px;
    border-radius: 50%;
    transition:all 0.2s ease;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
}
.whatsappLogo:hover{
box-shadow: rgba(0, 0, 0, 0.45) 0px 10px 20px;
}
nav ul {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
}
nav ul li {
    list-style: none;
    margin-left: 1.2rem;
}
nav a{
    color:var(--color-textGrey);
    text-decoration: none;
    border-bottom:2px solid white;
    transition: all 0.2s ease-in;
    font-size: 1.1rem;
}
nav a:hover {
    border-bottom-color:var(--color-green);
}

@media (max-width:640px) {
    header{
        padding: 0.5rem 1rem 0 1rem;
    }
    .headerLogo {
        width: 100px;
    }
    .headerTopRight a:not(:last-child) {                    
        font-size: 0.9rem;
    }
    .faPhone {
        height: 18px;
        margin-right: 8px;
        color: var(--color-textGrey);
    }            
    .faEnvelope {
        height: 18px;
        color: var(--color-textGrey);
    }
    .whatsappLogo{
        width: 50px;
    }
    nav ul li:not(:last-child) {
        display: none;
    }
    nav ul {
        margin-top: 0.5rem;
    }
}

